import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useNavigate, useParams } from 'react-router-dom';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CoinNav() {
  const { coinId } = useParams();  // Extract coinId from the URL
  const navigate = useNavigate();  // To programmatically navigate between routes
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // Define navigation based on the selected tab
    if (newValue === 0) {
      navigate(`/cryptocurrency/${coinId}`);
    } else if (newValue === 1) {
      navigate(`/cryptocurrency/about/${coinId}`);
    } else if (newValue === 2) {
      navigate(`/cryptocurrency/links/${coinId}`);
    }
  };

  useEffect(() => {
    // Set the correct tab based on the current route
    if (window.location.pathname.includes(`/about/`)) {
      setValue(1);
    } else if (window.location.pathname.includes(`/links/`)) {
      setValue(2);
    } else {
      setValue(0); // Default to Charts tab
    }
  }, [window.location.pathname]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs">
          <Tab label="Charts" {...a11yProps(0)} />
          <Tab label="About" {...a11yProps(1)} />
          <Tab label="Links" {...a11yProps(2)} />
        </Tabs>
      </Box>
    </Box>
  );
}
