import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import GridOnIcon from '@mui/icons-material/GridOn';

export default function ColorToggleButton() {
  const navigate = useNavigate();
  const location = useLocation();

  // Determine initial alignment based on the current pathname
  const initialAlignment = location.pathname === '/market-bubble' ? 'market-bubble' : 'table';
  const [alignment, setAlignment] = React.useState(initialAlignment);

  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      if (newAlignment === 'table') {
        navigate('/');
      } else if (newAlignment === 'market-bubble') {
        navigate('/market-bubble');
      }
    }
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >
      <ToggleButton value="table" color="primary">
          <GridOnIcon />
          Grid
      </ToggleButton>
      <ToggleButton value="market-bubble">
        <BubbleChartIcon />
        Buble
      </ToggleButton>
    </ToggleButtonGroup>
  );
}