import React, { useEffect, useState } from 'react'; 
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GitHubIcon from '@mui/icons-material/GitHub';
import LanguageIcon from '@mui/icons-material/Language';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import {
  Box,
  Grid,
  Typography,
  Card,
  IconButton,
  Link,
  CardContent,
  Skeleton,
  Alert,
} from '@mui/material';
import CoinNav from './CoinNav';
const apiUrl = process.env.REACT_APP_API_URL;

const CoinDetailsLink = () => {
  const { coinId } = useParams(); // Extract coinId from the URL
  const navigate = useNavigate();
  const [coinLinks, setCoinLinks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch coin details from the API
  useEffect(() => {
    axios
      .get(`${apiUrl}/api/getCoinLinks?coin=${coinId}`)
      .then((response) => {
        if (response.status === 200 && response.data.length > 0) {
          setCoinLinks(response.data); // Set the array of links from the API
        } else {
          setError(`No links found for coin: ${coinId}`);
        }
        setLoading(false);
      })
      .catch((error) => {
        setError(error.response?.data?.message || 'Error fetching coin data');
        setLoading(false);
      });
  }, [coinId]);

  return (
    <Box sx={{ p: 2, display: 'flex', justifyContent: 'center', minHeight: '100vh' }}>
      <Card
        sx={{
          width: '100%',
          maxWidth: { xs: '100%', md: '70vh' }, // Adjust card width based on screen size
          minWidth: { xs: '100%', md: '30vh' },
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            {/* Navigation tabs */}
            <Grid item xs={12}>
              <CoinNav />
            </Grid>

            {/* Links section */}
            <Grid item xs={12}>
              {loading ? (
                <Skeleton variant="text" width={200} />
              ) : error ? (
                <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>
              ) : (
                <>
                  <Typography variant="h6" gutterBottom>
                    {`Links for ${coinLinks[0]?.coin_name}:`}
                  </Typography>
                  {coinLinks.map((linkData, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      {/* Render icon based on link type */}
                      {linkData.link_type === 'Github' ? (
                        <GitHubIcon sx={{ mr: 1 }} />
                      ) : linkData.link_type === 'Website' ? (
                        <LanguageIcon sx={{ mr: 1 }} />
                      ) : linkData.link_type === 'Instagram' ? (
                        <InstagramIcon sx={{ mr: 1 }} />
                      ) : linkData.link_type === 'Facebook' ? (
                        <FacebookIcon sx={{ mr: 1 }} />
                      ) : (
                        <ArrowBackIcon sx={{ mr: 1 }} />
                      )}
                      
                      <Link
                        href={linkData.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        {linkData.link_type}: {linkData.link}
                      </Link>
                    </Box>
                  ))}
                </>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CoinDetailsLink;
