import React from 'react';

// Assuming you have an XIcon component
import XIcon from '@mui/icons-material/X';

const OpenLinkIcon = () => {
  const openLink = () => {
    window.open('https://x.com/stratalla', '_blank');
  };

  return <XIcon onClick={openLink} style={{ cursor: 'pointer' }} />;
};

export default OpenLinkIcon;