import React, { useRef, useEffect, useState, useCallback } from "react";
import * as d3 from "d3";
import axios from "axios";
import { CircularProgress, Box } from "@mui/material";
import DetailsBox from "./DetailsBox";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function Barchart() {
  const ref = useRef();
  const dataRef = useRef([]); // Using ref to store data to avoid re-renders
  const [loading, setLoading] = useState(true);
  const [selectedCoin, setSelectedCoin] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/getAllData`)
        
        dataRef.current = response.data; // Store data in ref
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const drawChart = useCallback(() => {
    const data = dataRef.current; // Access data from ref
    if (!data || data.length === 0) return;

    const container = ref.current;
    const width = container.clientWidth;
    const height = container.clientHeight;

    d3.select(container).selectAll("*").remove();

    const svg = d3.select(container)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const color = d3.scaleOrdinal()
      .domain(["positive", "negative", "neutral"])
      .range(["#00C853", "#F50000", "#d9d9d9"]);

    const size = d3.scaleLinear()
      .domain([0, 100])
      .range([Math.min(width, height) * 0.03, Math.min(width, height) * 0.1]);

    const simulation = d3.forceSimulation(data)
      .force("center", d3.forceCenter().x(width / 2).y(height / 2))
      .force("charge", d3.forceManyBody().strength(0.1))
      .force("collide", d3.forceCollide().strength(1).radius(d => size(d.bubble) + 3).iterations(3))
      .force("x", d3.forceX().x(d => Math.max(size(d.bubble), Math.min(width - size(d.bubble), d.x))))
      .force("y", d3.forceY().y(d => Math.max(size(d.bubble), Math.min(height - size(d.bubble), d.y))));

    const bubbles = svg.append("g")
      .selectAll("circle")
      .data(data)
      .enter()
      .append("circle")
      .attr("class", "bubble")
      .attr("r", d => size(d.bubble))
      .attr("cx", width / 2)
      .attr("cy", height / 2)
      .style("fill", d => color(d.bubbletype))
      .style("fill-opacity", 0.8)
      .on("click", (event, d) => {
        handleBubbleClick(d);
      })
      .call(drag(simulation));

    const textGroups = svg.append("g")
      .selectAll("g")
      .data(data)
      .enter()
      .append("g")
      .attr("class", "text-group")
      .on("click", (event, d) => {
        handleBubbleClick(d);
      }); // Add click event to text groups

    const textCoinName = textGroups.append("text")
      .attr("class", "label")
      .style("text-anchor", "middle")
      .style("font-size", d => `${Math.min(12, size(d.bubble) / 3)}px`)
      .style("font-weight", "bold")
      .attr("dy", "-0.5em")
      .text(d => d.coinname);

    const textRun = textGroups.append("text")
      .attr("class", "label")
      .style("text-anchor", "middle")
      .style("font-size", d => `${Math.min(16, size(d.bubble) / 2.5)}px`)
      .attr("dy", "0.5em")
      .text(d => (d["Run 20 days"] !== undefined ? `${d["Run 20 days"].toFixed(2)}%` : ""));

    simulation.on("tick", () => {
      bubbles.attr("cx", d => Math.max(size(d.bubble), Math.min(width - size(d.bubble), d.x)))
             .attr("cy", d => Math.max(size(d.bubble), Math.min(height - size(d.bubble), d.y)));
      textGroups.attr("transform", d => `translate(${Math.max(size(d.bubble), Math.min(width - size(d.bubble), d.x))}, ${Math.max(size(d.bubble), Math.min(height - size(d.bubble), d.y))})`);
    });

    function drag(simulation) {
      function dragstarted(event, d) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
      }

      function dragged(event, d) {
        d.fx = Math.max(size(d.bubble), Math.min(width - size(d.bubble), event.x));
        d.fy = Math.max(size(d.bubble), Math.min(height - size(d.bubble), event.y));
      }

      function dragended(event, d) {
        if (!event.active) simulation.alphaTarget(0);
        d.fx = null;
        d.fy = null;
      }

      return d3.drag().on("start", dragstarted).on("drag", dragged).on("end", dragended);
    }
  }, []);

  useEffect(() => {
    if (!loading && dataRef.current.length > 0) {
      drawChart();
    }

    const handleResize = () => {
      if (!loading && dataRef.current.length > 0) {
        drawChart();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [loading, drawChart]);

 

  const handleBubbleClick = (d) => {
    setSelectedCoin(d.coinname);
  };

  const handleCloseDetails = () => {
    setSelectedCoin(null);
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Bar Chart Visualization</title>
        <meta name="description" content="Interactive crpyto bubble chart for cryptocurrency data." />
        <meta name="keywords" content="Crypto bubble chart, Crypto, Cryptocurrency, Stocks " />
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href="https://stratalla.com/market-bubble" />
        <meta property="og:title" content="Crypto bubble chart" />
        <meta property="og:description" content="Explore the interactive bubble chart visualization for cryptocurrency data." />
        <meta property="og:url" content="https://stratalla.com/market-bubble" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://stratalla.com/logo.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Crypto bubble chart" />
        <meta name="twitter:description" content="Explore the interactive crypto bubble chart visualization for cryptocurrency data." />
        <meta name="twitter:image" content="https://stratalla.com/logo.png" />
      </Helmet>
      <div className="Barchart" style={{ width: '100%', height: '100vh', position: 'relative' }}>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100vh',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <div ref={ref} style={{ width: '100%', height: '100%' }} />

            {selectedCoin && (
              <DetailsBox
                data={dataRef.current}
                selectedCoin={selectedCoin}
                onClose={handleCloseDetails}
              />
            )}
          </>
        )}
      </div>
    </HelmetProvider>
  );
}