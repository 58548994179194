import axios from 'axios';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import Chip from '@mui/material/Chip';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { Skeleton, Button, Menu, MenuItem, TextField, Checkbox,Typography } from '@mui/material';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import Autocomplete from '@mui/material/Autocomplete';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import MediumPriorityIcon from '@mui/icons-material/ArrowUpward';  // ArrowUp for medium
import HighPriorityIcon from '@mui/icons-material/ArrowUpward';  // Upward arrow for high, can be more intense
import SignificantIcon from '@mui/icons-material/KeyboardArrowUp';  // Bigger upward arrow for significant
import FlatIcon from '@mui/icons-material/Remove'; 
import NightlightIcon from '@mui/icons-material/Nightlight';
import BoltIcon from '@mui/icons-material/Bolt';
import PowerIcon from '@mui/icons-material/Power';
 
import { alignProperty } from '@mui/material/styles/cssUtils';
import { margin } from '@mui/system';

const apiUrl = process.env.REACT_APP_API_URL;

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const IOSSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#0a84ff' : '#e0e0e0',
  height: 5,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 12,
    width: 12,
    backgroundColor: '#dedede',
    boxShadow: '0 0 2px 0px rgba(0, 0, 0, 0.1)',
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.1)',
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
    '&:before': {
      boxShadow:
        '0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)',
    },
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 14,
    fontWeight: 'regular',
    top: 20,
    left: 10,
    backgroundColor: 'unset',
    color: theme.palette.text.primary,
    '&::before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
      color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
    height: 5,
    color: '#bfbebd',
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    boxShadow: 'inset 0px 0px 4px -2px #000',
    backgroundColor: '#d0d0d0',
  },
}));

const columns = [
  { field: 'id', headerName: 'Id', width: 15, disableColumnMenu: true},
  {
    field: 'coinname', headerName: 'Coin', width: 70,
    renderCell: (params) => <a href={"https://www.binance.com/en/trade/" + params.value + "_USDT?type=spot"} target="_blank" rel="noopener noreferrer"><img src={process.env.PUBLIC_URL + "/PNG/" + params.value + ".png"} width="25" height="25" style={{ margin: '16px 0px 0px 0px' }} alt={params.value} /></a>,
  },
  { field: 'Coin', headerName: 'Name',renderCell: (params) => {
    const coinName = params.value.replace('USDT', '');
    return (
      <Typography
        component="a"
        href={`/cryptocurrency/${coinName}`}
        variant="body2"
        fontSize="0.7rem"
        sx={{
          textDecoration: 'none',
          color: 'inherit',
          '&:hover': {
            textDecoration: 'underline',
            color: 'blue',
          },
        }}
      >
        {params.value.replace('USDT', '')}
      </Typography>
    );
  }, width: 120 },
  {
    field: 'Close price',
    headerName: 'Price',
    width: 100,
    renderCell: (params) => {
      return `$ ${params.value} `;
    }
  },
  {
    field: 'level20',
    headerName: 'Short-term price',
    width: 130,
    renderCell: (params) => {
      return `$ ${params.value} `;
    }
  },
  {
    field: 'Run 20 days',
    headerName: 'Short-term %',
    width: 130,
    renderCell: (params) => {
      const condition = params.value < 0;
      return <Chip icon={condition ? <ExpandMoreIcon /> : <ExpandLessIcon />} label={`${params.value.toLocaleString()} %`} variant={"outlined"} color={condition ? "error" : "success"} />;
    }
  },
  {
    field: 'level',
    headerName: 'Mid-term price',
    description: "Swing level within 50 periods",
    width: 130,
    renderCell: (params) => {
      return `$ ${params.value} `;
    }
  },
  {
    field: 'Run 50 days',
    headerName: 'Mid-term %',
    width: 130,
    renderCell: (params) => {
      const condition = params.value < 0;
      return <Chip icon={condition ? <ExpandMoreIcon /> : <ExpandLessIcon />} label={`${params.value.toLocaleString()} %`} variant={"outlined"} color={condition ? "error" : "success"} />;
    }
  },
  {
    field: 'evr',
    headerName: 'Volume ratio',
    description: "Volume ration from moving average",
    width: 130,
    renderCell: (params) => {
      return `x ${params.value.toLocaleString()} `;
    }
  },
  {
    field: 'volume_category',
    headerName: 'Volume category',
    description: "Volume category: low, high, medium, significant",
    width: 180,
    renderCell: (params) => {
      // Define enhanced colors, icons, and other visual styling for each category
      const categoryData = {
        '1-Low': {
          label: '1-Low',
          color: '#b0bec5',  // Light gray
          icon: <NightlightIcon style={{ fontSize: 20 }} />,  // Neutral icon, flat line
        },
        '2-Medium': {
          label: '2-Medium',
          color: '#42a5f5',  // Vibrant blue
          icon: <PowerIcon style={{ fontSize: 20,color: '#ffffff'}} />,  // Up arrow for medium
          
        },
        '3-High': {
          label: '3-High',
          color: '#ffb74d',  // Bright orange
          icon: <BoltIcon style={{ fontSize: 20, color: '#fffe00' }} />,  // Up arrow for high
        },
        '4-Significant': {
          label: '4-Significant',
          color: '#ef5350',  // Strong red
          icon: <WhatshotIcon style={{ fontSize: 25,color: '#fed962'  }} />,  // Bigger up arrow for  significant
        },
      };
  
      const category = categoryData[params.value] || {
        label: 'Unknown',
        color: '#9e9e9e',  // Default gray color
        icon: null,
      };
  
      return (
        <Chip
          label={category.label}
          icon={category.icon}
          style={{
            backgroundColor: category.color,
            color: 'white',  // White text to contrast the background
            fontWeight: 'bold',  // Make text bolder for emphasis
            borderRadius: '10px',  // Rounded edges
          }}
        />
      );
    }
  },
  {
    field: 'alltimehigh',
    headerName: 'ATH',
    description: 'Percentage of all-time high',
    width: 100,
    renderCell: (params) => {
      return `$ ${params.value} `;
    }
  },
  {
    field: '% ATH',
    headerName: '% ATH',
    description: "% down from all time high",
    width: 160,
    renderCell: (params) => {
      return <Box sx={{ width: 100, margin: '12px 0px 0px 5px' }}><IOSSlider aria-label="ios slider" valueLabelDisplay="on"
        defaultValue={params.value}
        disabled
        min={-100}
        max={0}
        size='small' />
      </Box>
    }
  },
  {
    field: 'details',
    headerName: 'View On',
    width: 100,
    renderCell: (params) => <DetailsCell {...params} />
  },
  {
    field: 'action',
    headerName: 'Action',
    width: 150,
    renderCell: (params) => <ActionCell {...params} />
  }


];

function ActionCell(props) {
  const { row } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const exchanges = [
    { name: 'Binance', icon: 'binance.png', link: `https://www.binance.com/en/trade/${row.coinname}_USDT?type=spot` },
    { name: 'Gate.io', icon: 'gateio.png', link: `https://www.gate.io/trade/${row.coinname}_USDT` },
  ];

  return (
    <div>
      <Button size="small" variant="contained" color="primary" onClick={handleClick} startIcon={<ChangeCircleIcon />}>
        Trade {row.coinname}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {exchanges.map((exchange) => (
          <MenuItem
            key={exchange.name}
            onClick={() => {
              window.open(exchange.link, '_blank');
              handleClose();
            }}
          >
            <img src={process.env.PUBLIC_URL + '/PNG/' + exchange.icon} alt={exchange.name} width="24" height="24" style={{ marginRight: '10px' }} />
            {exchange.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
function DetailsCell(props) {
  const { row } = props;

  const handleDetailsClick = () => {
    const tradingViewUrl = `https://www.tradingview.com/chart/?symbol=BINANCE:${row.coinname}USDT&share_your_love=djoleivanovic`;
    
    window.open(tradingViewUrl, '_blank');
  };

  return (
    <img
      src={`${process.env.PUBLIC_URL}/PNG/tv.png`}
      alt="Trading View"
      width="24"
      height="24"
      style={{ cursor: 'pointer', margin:'13px 0px 0px 20px' }}
      onClick={handleDetailsClick}
    />
  );
}

export default function DataTable() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCoins, setSelectedCoins] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/getAllData`)
      //const response = await axios.get("/api/api/getAllData");
      //console.log('API Response:', response.data);

      if (Array.isArray(response.data)) {
        setData(response.data);
        setFilteredData(response.data);
      } else {
        console.error('Expected array but got:', typeof response.data);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 60000); // Poll every 5 minutes

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const handleCoinChange = (event, newValue) => {
    setSelectedCoins(newValue);
    if (newValue.length === 0) {
      setFilteredData(data);
    } else {
      setFilteredData(data.filter(row => newValue.includes(row.coinname)));
    }
  };

  const coinOptions = data.map(row => row.coinname);

  return (
    <div style={{ height: 1050, width: '100%',paddingBottom:'40px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <Autocomplete
          size="small"
          multiple
          options={coinOptions}
          value={selectedCoins}
          onChange={handleCoinChange}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          renderOption={(props, option, { selected }) => (
            <li {...props} style={{ padding: '3px 3px', display: 'flex', alignItems: 'center' }}>
              <Checkbox
                checked={selected}
                style={{ marginRight: 8 }}
              />
              {option}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Filter by Coins" placeholder="Select coins" />
          )}
          sx={{ marginBottom: '10px', width: '600px' }}
        /> 
        <Typography variant="caption" display="block" sx={{ flexGrow: 1, textAlign: 'right', marginLeft: '20px' }}>

          Auto refresh each 5 min    
        </Typography>
        </Box>
        {loading ? (
          <Skeleton variant="rectangular" width="100%" height="100%" />
        ) : (
          <DataGrid
            rows={filteredData}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[100]}
            hideFooter
          />
        )}
    </div>
  );
}
