import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Avatar from '@mui/material/Avatar';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Datagrid from './Datagrid';
import Datagridmobile from './Datagridmobile';
import Barchart from './Barchart';
import ToggleButton from './ToggleButton';
import Filterpage from './Filterpage';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useMediaQuery } from '@mui/material';
import Twitter from './Twitter';
import Logo from './Logosite';
import About from './About';
import CoinDetails from './CoinDetails';
import CandlestickChart from './CandlestickChart';
import SignUp from './Signup';
import CoinDetailsAbout from './CoinDetailsAbout'; // To be created
import CoinDetailsLink from './CoinDetailsLink';   // To be created

// Define Copyright component
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://stratalla.com/">
        Stratalla
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const defaultTheme = createTheme();

export default function Dashboard() {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const isMobile = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();
  const location = useLocation(); // Get the current route

  // Check if the current route is Filterpage
  const isFilterPage = location.pathname === '/filterpage';
  const navigateAboutUs = () => {
    navigate('/about'); // Navigate to the About page
  };
  return (
    <HelmetProvider>
      <Helmet>
        <title>Stratalla Dashboard</title>
        <meta name="description" content="Stratalla Dashboard - Screen for crpyto and stock." />
        <meta name="keywords" content="Stratalla, Dashboard, Cryptocurrency, Analytics, Stocks, Crypto" />
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href="https://stratalla.com/dashboard" />
      </Helmet>
      <ThemeProvider theme={defaultTheme}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar position="absolute" open={open} sx={{ bgcolor: "#000000" }}>
            <Toolbar sx={{ pr: '24px', display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ margin: "0px 20px 0px 0px" }}>
                  <Logo />
                </div>
              </Box>
              <Box sx={{ flexGrow: 1 }}></Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Link to="/about" style={{ textDecoration: 'none' }}>
                  <Typography variant="body1" sx={{ margin: "0px 20px 0px 0px", color: 'inherit',cursor: 'pointer',textDecoration: 'underline'  }} onClick={navigateAboutUs}>
                    About us
                  </Typography>
                </Link>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ margin: "0px 20px 0px 0px" }}>
                  Follow us
                </div>
                <Twitter />
              </Box>
            </Toolbar>
          </AppBar>
          <Box
            component="main"
            sx={{
              width: '100%',
              backgroundColor: (theme) =>
                theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar />
            <Container maxWidth="xxl" sx={{ mt: 3, mb: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {!isFilterPage && <ToggleButton />} {/* Hide ToggleButton on Filterpage */}
            </Container>
            <Box sx={{ mt: 2, mb: 4 }}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <Grid container spacing={3} justifyContent="center">
                      <Grid item xs={12} md={10}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          {isMobile ? <Datagridmobile /> : <Datagrid />}
                        </Paper>
                      </Grid>
                    </Grid>
                  }
                />
                <Route path="/market-bubble" element={<Barchart />} />
                <Route path="/filterpage" element={<Filterpage />} /> {/* Added route for Filterpage */}
                <Route path="/datagridmobile" element={<Datagridmobile />} />
                <Route path="/about" element={<About />} />
                <Route path="/cryptocurrency/:coinId" element={<CoinDetails />} />
                <Route path="/candle" element={<CandlestickChart />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/cryptocurrency/:coinId" element={<CoinDetails />} />
                <Route path="/cryptocurrency/about/:coinId" element={<CoinDetailsAbout />} />
                <Route path="/cryptocurrency/links/:coinId" element={<CoinDetailsLink />} />

              </Routes>
              <Copyright sx={{ pt: 4 }} />
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </HelmetProvider>
  );
}
