import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Grid,
  Typography,
  Card,
  Chip,
  CardContent,
  Avatar,
  Link,
  Skeleton,
  Alert
} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import InfoIcon from '@mui/icons-material/Info';
import PersonIcon from '@mui/icons-material/Person';
import CoinNav from './CoinNav'; // Assume this is the navigation component
import { Helmet, HelmetProvider } from 'react-helmet-async';
const apiUrl = process.env.REACT_APP_API_URL;
const url= process.env.REACT_APP_URL

const CoinDetailsAbout = () => {
  const { coinId } = useParams(); // Extract coinId from the URL
  const [coinData, setCoinData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch coin details from the API
  useEffect(() => {
    axios.get(`${apiUrl}/api/getCoinDataDetails?coin=${coinId}`)
      .then(response => {
        if (response.status === 200) {
          setCoinData(response.data[0]); // Assuming the API returns an array
        } else {
          setError(response.data.message || 'Unknown error occurred');
        }
        setLoading(false);
      })
      .catch(error => {
        setError(error.response?.data?.message || 'Error fetching coin data');
        setLoading(false); // Stop loading even on error
      });
  }, [coinId]);

  // Helper function to parse related coins like 'ETC (Ethereum Classic)'
  const parseCoinName = (relatedCoin) => {
    const [coinSymbol] = relatedCoin.split(' ');
    return coinSymbol; // Return 'ETC' from 'ETC (Ethereum Classic)'
  };

  if (loading) {
    return (
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'center', minHeight: '100vh' }}>
        <Skeleton variant="rectangular" width={300} height={200} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'center', minHeight: '100vh' }}>
              <Card
        sx={{
          width: '100%',
          maxWidth: { xs: '100%', md: '70vh' },
          minWidth: { xs: '100%', md: '30vh' },
        }}
      >
        
        <CardContent>
          <Grid container spacing={2}>
            {/* Navigation tabs */}
            <Grid item xs={12}>
              <CoinNav />
            </Grid>

            {/* Content */}
            <Grid item xs={12}>
              {loading ? (
                <Skeleton variant="rectangular" width="100%" height={200} />
              ) : error ? (
                <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>
              ) : (
                <Typography variant="h6" gutterBottom>
                  {coinData ? 'Coin Data Details' : 'No data found'}
                </Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>
 
        </Card>

      </Box>
    );
  }

  return (
    <Box sx={{ p: 2, display: 'flex', justifyContent: 'center', minHeight: '100vh' }}>
      <Card
        sx={{
          width: '100%',
          maxWidth: { xs: '100%', md: '70vh' },
          minWidth: { xs: '100%', md: '30vh' },
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CoinNav />
            </Grid>

            {/* Categories as chips */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>Categories:</Typography>
              {coinData.categories ? (
                coinData.categories.split(', ').map((category, index) => (
                  <Chip key={index} label={category} sx={{ mr: 1, mb: 1 }} />
                ))
              ) : (
                <Typography>No categories available</Typography>
              )}
            </Grid>

            {/* Description */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>Description:</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <DescriptionIcon sx={{ mr: 1 }} />
                <Typography>{coinData.description || 'No description available'}</Typography>
              </Box>
            </Grid>

            {/* Purpose */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>Purpose:</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <InfoIcon sx={{ mr: 1 }} />
                <Typography>{coinData.purpose || 'No purpose available'}</Typography>
              </Box>
            </Grid>

            {/* Founders */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>Founders:</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <PersonIcon sx={{ mr: 1 }} />
                <Typography>{coinData.founders || 'No founders available'}</Typography>
              </Box>
            </Grid>

            {/* Related coins */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>Related Coins:</Typography>
              {coinData.related_coins ? (
                coinData.related_coins.split(', ').map((relatedCoin, index) => {
                  const coinSymbol = parseCoinName(relatedCoin);
                  return (
                    <Chip
                      key={index}
                      label={
                        <Box display="flex" alignItems="center">
                          <Box
                            component="img"
                            src={`${process.env.PUBLIC_URL}/PNG/${coinSymbol}.png`}
                            alt={relatedCoin}
                            sx={{ width: 20, height: 20, mr: 1 }}
                          />
                          <a
                            href={`${url}/cryptocurrency/${coinSymbol}`}
                            style={{ textDecoration: 'none', color: 'inherit' }}
                          >
                            {coinSymbol} crypto
                          </a>
                        </Box>
                      }
                      sx={{ mr: 1, mb: 1 }}
                    />
                  );
                })
              ) : (
                <Typography>No related coins available</Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CoinDetailsAbout;
